.login-bg,
.login-main,
.login-main-header-logo,
.login-main-content-image {
    height: 100%;
}

.login-bg {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.login-main-header-vicelogo {
    height: 24px;
    margin-left: 21px;
}

.login-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-main-header {
    height: 36px;
    position: absolute;
    left: 50px;
    top: 50px;
    display: flex;
    align-items: center;
}

.login-main-content {
    width: 1080px;
    height: 557px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form-item {
    flex-shrink: 0;
    width: 350px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-form-header {
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    position: relative;
    color: #333;
}

.login-form-header::after {
    content: "";
    width: 40px;
    height: 3px;
    margin: auto;
    border-radius: 2px;
    background-color: #00C889;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.login-form-more {
    flex-shrink: 0;
    float: right;
    display: flex;
    align-items: center;
}

.login-form-line {
    width: 1px;
    height: 15px;
    margin: 0 5px;
    background-color: #00C889;
}

.login-form-input {
    flex-grow: 1;
    width: 350px;
    height: 64px;
    /* margin-top: 20px; */
    font-size: 14px;
}

.ant-form-item-control-input-content .login-form-input {
    border-bottom: 1px solid;
    border-color: #eee;
}

.ant-form-item-control-input-content .login-form-input:hover {
    border-color: #00C889;
}

.login-form-register,
.login-form-forgot {
    font-size: 14px;
    color: #00C889;
}

.login-form-btn {
    width: 350px;
    height: 56px;
    margin-top: 64px;
    border-radius: 28px;
    font-size: 18px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00C889;
    border-color: #00C889;
}

.ant-checkbox-checked::after {
    border: 1px solid #00C889;
}

.login-form-floatright {
    justify-content: flex-end;
}

.login-form-link,
.login-form-link:hover,
.login-form-link:active {
    flex-shrink: 0;
    color: #00C889;
}

.login-main-content-image {
    width: 540px;
}


/* .ant-input:focus,
.ant-input-focused {
    border-color: #00C889;
} */