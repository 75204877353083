@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './custom-theme-file.css';
@import 'antd-theme-file.less';

html,
body,
#root,
.App,
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

html,
body {
  color: #333333
}

@media screen and (min-width: 1366px) {
  html,
  body {
    min-width: 1366px;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.header {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f4f7;
}

.body {
  display: flex;
  width: 100%;
  height: calc(100% - 90px);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 220px;
  height: 100%;
  border-right: 1px solid #f2f4f7;
}

.icon-collapsed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 80px;
  height: 100%;
  border-right: 1px solid #f2f4f7;
}

.side-bar {
  flex-shrink: 0;
  width: 220px;
  height: 100%;
}

.side-bar-collapsed {
  flex-shrink: 0;
  width: 80px;
  height: 100%;
}

.content {
  width: 100%;
  height: 100%;
  background: #F7F9FA;
  overflow: auto;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #D9D9D9;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #fafafa;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

