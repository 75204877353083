//文档：https://ant-design.gitee.io/docs/react/customize-theme-cn
//https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@primary-color: #00C889;

@border-radius-base: 4px;

//@pagination-item-bg: #F9F9FA;

//@pagination-item-bg-active: @primary-color;

@table-header-bg: #F9F9FA;

.ant-table-thead>tr>th {
  border-bottom: 0;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #F2f4f7;
}

.ant-table-cell {
  margin-left: 24px;
}


@select-item-selected-color: #00c889;
@select-item-active-bg: #edfaf7;


//@message-notice-content-bg: #00C889;

/*标签*/
.ant-tag-red {
  background-color: #FFF2ED;
  border-color: #FF5B1A;
  color: #FF5B1A;
}

.ant-tag-green {
  background-color: #FAFFF4;
  border-color: #6DD400;
  color: #6DD400;
}

.ant-tag-orange {
  background-color: #FFFAEF;
  border-color: #FFB11A;
  color: #FFB11A;
}

.ant-tag-blue {
  background-color: #F4FCFF;
  border-color: #32C5FF;
  color: #32C5FF;
}

.ant-tag-lime {
  background-color: #EEFFFA;
  border-color: #00C889;
  color: #00C889;
}

.ant-tag {
  padding: 2px 12px;
  border-radius: 4px;
}

/*表格*/
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 13px 16px;
}

/*描述列表*/
.ant-descriptions-bordered .ant-descriptions-view {
  border-color: #f2f4f7;
}

/*日期*/
.ant-picker-range {
  width: 100%;
}

/*去除默认高亮*/
.ant-input,
.ant-input:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.ant-input,
.ant-picker,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper {
  border-color: #e5e5e5;
  box-shadow: none;
}

.ant-table-thead>tr>th,
.ant-form-item-label>label,
.ant-descriptions-row>th {
  color: #333;
}

.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td,
.ant-input,
.ant-picker,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-descriptions-row>td {
  color: #666;
}
