/* 覆盖antd样式 */

/* .ant-btn:hover, .ant-btn:focus,
  .ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: #00C889;
    border-color:#00C889
  } */

/*.ant-btn:hover, .ant-btn:hover {
    background: #00C889;
    border-color:#00C889;
    color: #fff;
  }
  .ant-btn-primary {
    background: #00C889;
    border-color:#00C889;
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    background: #36e9b0;
    border-color:#36e9b0;
  }

  !* 选择框focus的样式 *!
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color:#00C889;
    -webkit-box-shadow: 0 0 0 2px #D0F5F1(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px #D0F5F1(24, 144, 255, 0.2);
  }
  !* 选择框hover的样式 *!
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #00C889;
    border-right-width: 1px !important;
  }
  !* 选择框Option的样式 *!
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #fff;
    background-color: #00dd99;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    font-weight: 600;
    background-color: #00C889;
  }
  !* 菜单栏样式 *!
  .ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #00C889;
  }
  .ant-menu-submenu {
    color: #333333;
  }
  .ant-menu-submenu :hover {
    color: #00C889;
  }
  .ant-menu-submenu-selected {
    color: #00C889;
  }
.ant-picker-range {
    color: #00C889;
  }
  .ant-menu-submenu-vertical-selected {
    color: #00C889;
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #00C889;
  }
  .ant-menu.ant-menu .ant-menu-item a {
    color: #999999;
  }
  .ant-menu.ant-menu .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu .ant-menu-item-selected {
    background-color: #00C889;
    color: #fff;
  }
  .ant-menu.ant-menu .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #fff;
  }
  !* Menu右边那一竖 *!
  .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
    opacity: 0;
  }

  .ant-input-affix-wrapper:hover {
    border-color: #00C889;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #00C889;
  }
  !* 翻页器 *!
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: #00C889;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #00C889;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #00C889;
  }
  .ant-pagination-item-active a {
    color: #00C889;
  }
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #00C889;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #00C889;
  }
  !* 树选择器 *!
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #00C889;
  }
  !* 输入框 *!
  .ant-input:focus,
  .ant-input-focused {
    border-color: #00C889;
  }*/

.prefix-decorate::before {
  content: '';
  position: absolute;
  height: 18px;
  width: 4px;
  background-color: #00C889;
  border-radius: 20px;
  margin-top: 8px;
  left: -10px;
}

.prefix-decorate {
  margin-left: 8px;
}

/* g6-提示框 */

.g6-tooltip {
  padding: 10px 6px;
  color: #444;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

/* 企业关系网-minimap样式 */

.realation-net-mini-map {
  position: absolute;
  right: 25px;
  bottom: 25px;
  background-color: #fff;
  border: 1px solid #eee;
  z-index: 2;
}

.realation-net-viewport {
  outline: #00C889 solid 2px !important;
}

/* 隐藏地图logo */

.l7-ctrl-logo {
  display: none !important;
}
