@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.h5-bg {
    width: 100vw;
    height: 100vh;
    padding: 0 .94rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    text-align: center;
    position: relative;
}

.h5-title,
.h5-subtitle {
    color: #fff;
}

.h5-title {
    padding-top: 7rem;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 1.68rem;
    letter-spacing: .1rem;
}

.h5-subtitle {
    margin-top: 1.5rem;
    font-size: 1.18rem;
    margin-right: -1.5rem;
    letter-spacing: 1.6rem;
}

.h5-popup {
    padding: 1rem;
    margin: 10rem 0;
    background-color: rgba(255, 255, 255, .8);
    border-radius: .3rem;
    font-size: 0.88rem;
    color: #001F16;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.h5-popup-link {
    flex-grow: 1;
    width: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.h5-popup-btn {
    flex-shrink: 0;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    color: #00AB76;
}

.h5-popup-btn img {
    width: .88rem;
    margin-left: .22rem;
}

.h5-noBalanceTip {
    width: 21.56rem;
    height: 10.97rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.3rem;
    position: absolute;
    bottom: 9.34rem;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h5-noBalanceTip img {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 0.88rem;
    margin-bottom: 0.94rem;
}

.h5-noBalanceTip-mainTit {
    width: 6rem;
    /* height: 1rem; */
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-bottom: 0.75rem;
}

.h5-noBalanceTip-viceTit {
    width: 13.5rem;
    height: 3rem;
    font-size: 0.75rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 1.13rem;
}


.h5-btn {
    min-width: 18rem;
    height: 2.75rem;
    line-height: 2.75rem;
    margin: 0 auto;
    background: linear-gradient(-90deg, #00E6A9 0%, #00C88A 100%);
    box-shadow: 0rem 0rem 0rem 0rem rgba(0, 205, 143, 0.1);
    border-radius: 30px;
    font-size: 1rem;
    color: #fff;
    border: none;
    position: absolute;
    bottom: 3rem;
    right: 0;
    left: 0;
}

.h5-btn img {
    width: 1.31rem;
    margin-left: 0.59rem;
    animation: rotate 1s linear infinite;
}